<template>
    <Modal v-model="show" title="添加用户" :loading="loading" :width="640" :mask-closable="false">
        <Form ref="addForm" :model="addForm" :rules="addFormRules" label-colon :label-width="90">
            <FormItem label="角色" prop="roleIdList">
                <Select v-model="addForm.roleIdList" multiple placeholder="请选择">
                    <Option v-for="item in roleArray" :label="item.roleName" :value="item.roleId"
                            :key="item.roleId"></Option>
                </Select>
            </FormItem>
            <FormItem label="姓名" prop="name">
                <Input v-model.trim="addForm.name" show-word-limit :maxlength="20" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="性别" prop="gender">
                <RadioGroup v-model="addForm.gender">
                    <Radio label="男">男</Radio>
                    <Radio label="女">女</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="联系方式" prop="contactInfo">
                <Input v-model.trim="addForm.contactInfo" show-word-limit :maxlength="20" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="员工编号" prop="empNo">
                <Input type="text" v-model.trim="addForm.empNo" show-word-limit :maxlength="20" placeholder="请输入"/>
            </FormItem>
            <FormItem label="所属组织" prop="departmentIds">
                <TreeSelect v-model="addForm.departmentIds" :options="departmentOpts" :multiple="true" :flat="true" placeholder="请选择" noOptionsText="暂无数据" noResultsText="没有匹配数据" :clear-on-select="clearOnSelect" clearable />
            </FormItem>
            <FormItem label="用户名" prop="userName">
                <Input v-model.trim="addForm.userName" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="密码" prop="password">
                <Input type="password" v-model="addForm.password" placeholder="请输入" clearable/>
            </FormItem>
        </Form>

        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" @click="submit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqGetRoleList, reqAddUser} from "../../../api/system-api";
import {checkPhone} from "@/common/publicFuns";
import {reqGetDepartmentTreeOpts} from "../../../api/admin-api";
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {getLabelById} from "../../../assets/js/treeFun";

export default {
    name: "addUser",
    components: {TreeSelect},
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            show: this.value,
            loading: true,
            clearOnSelect: true,
            addForm: {
                name: '',
                contactInfo: '',
                userName: "",
                password: '',
                roleIdList: '',
                gender: '男',
                empNo: null,
                departmentIds: []
            },
            roleArray: [],
            departmentOpts: [],
            addFormRules: {
                name: [
                    {required: true, message: '请输入姓名', trigger: 'blur'},
                    {min: 2, max: 20, message: '姓名不能超过20个字符', trigger: 'blur'},
                ],
                userName: [
                    {required: true, message: '请输入用户名', trigger: 'blur'},
                    {
                        pattern: /^[a-zA-Z]{1}\w{5,19}$/,
                        message: '用户名以字母开头，包含字母、数字、下划线，长度6到20位',
                        trigger: 'blur'
                    },
                ],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                    {
                        pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![!#$%^&*]+$)[\da-zA-Z!#$%^&*]{6,16}$/,
                        message: '密码由字母和数字组成6到16位',
                        trigger: 'blur'
                    },
                ],
                contactInfo: [{
                    trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (value && !checkPhone(value)) {
                            return callback(new Error('联系电话不正确(座机格式\'区号-座机号码\')'));
                        }
                        return callback();
                    }
                }],
                gender: [{required: true, message: '请选择性别', trigger: 'blur'}],
            },
        }
    },
    methods: {
        // base64加密开始
        encode64(input) {
            var keyStr = "ABCDEFGHIJKLMNOP" + "QRSTUVWXYZabcdef" + "ghijklmnopqrstuv"
                + "wxyz0123456789+/" + "=";
            var output = "";
            var chr1, chr2, chr3 = "";
            var enc1, enc2, enc3, enc4 = "";
            var i = 0;
            do {
                chr1 = input.charCodeAt(i++);
                chr2 = input.charCodeAt(i++);
                chr3 = input.charCodeAt(i++);
                enc1 = chr1 >> 2;
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                enc4 = chr3 & 63;
                if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
                } else if (isNaN(chr3)) {
                    enc4 = 64;
                }
                output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2)
                    + keyStr.charAt(enc3) + keyStr.charAt(enc4);
                chr1 = chr2 = chr3 = "";
                enc1 = enc2 = enc3 = enc4 = "";
            } while (i < input.length);

            return output;
        },
        submit() {
            this.$refs['addForm'].validate((valid) => {
                let that = this;
                if (valid) {
                    this.addForm.password = this.encode64(this.encode64(this.addForm.password) + 'pd');
                    this.closeModal(true);
                    that.loading = true;
                    let para = Object.assign({}, this.addForm);
                    reqAddUser(para).then(res => {
                        if (res.data.code === 1) {
                            this.closeModal(false);
                            this.$emit('getUserList');
                            that.loading = false;
                            that.$Message.success(res.data.msg);
                        } else if (res.data.code === 7) {
                            // this.closeModal(false);
                            that.loading = false;
                            that.$Message.warning(res.data.msg);
                        } else {
                            // this.closeModal(true);
                            that.loading = false;
                            that.$Message.warning(res.data.msg);
                        }
                    }).catch(() => {
                        this.closeModal(false);
                        that.$Message.error('添加失败');
                    })
                }
                setTimeout(() => {
                    this.loading = false;
                    this.$nextTick(() => {
                        this.loading = true;
                    });
                }, 1000);
            });
        },
        //获取全部角色
        getAllRole() {
            reqGetRoleList().then((res) => {
                this.roleArray = res.data
            })
        },
        getDepartmentTreeOpts() {
            reqGetDepartmentTreeOpts().then(res => {
                this.departmentOpts = res.data.data;
            }).catch(()=>{
                this.departmentOpts = [];
            });
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    mounted() {},
    watch: {
        value(val) {
            this.show = val;
        },
        show(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.addForm.roleIdList = [];
                this.$refs['addForm'].resetFields();

                this.getAllRole();
                this.getDepartmentTreeOpts();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
