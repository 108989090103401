<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>系统管理</BreadcrumbItem>
                <BreadcrumbItem>用户管理</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem>
                    <Input v-model="filter.name" placeholder="请输入姓名" clearable/>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.roleId" placeholder="请选择角色" clearable>
                        <Option v-for="item in roleArray" :value="item.roleId" :key="item.roleId">{{ item.roleName }}
                        </Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Input v-model="filter.phone" placeholder="请输入联系方式" clearable/>
                </FormItem>
                <FormItem>
                    <TreeSelect v-model="filter.departmentId" :options="departmentOpts" placeholder="请选择所属组织" noOptionsText="暂无数据" noResultsText="没有匹配数据" clearable />
                </FormItem>
                <FormItem>
                    <Button type="primary" v-on:click="getUserList">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" icon="md-add" @click="showAddDialog" v-if="permissionButton.add">新增</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="userList" :loading="loading">
                    <template slot-scope="{ row, index }" slot="isDisabled">
                        <span>{{ row.isDisabled === '0' ? '启用' : '禁用' }}</span>
                    </template>
                    <template slot-scope="{ row, index }" slot="action">
                        <a @click="showViewUser(row,index)">详情</a>
                        <Divider type="vertical"/>
                        <a v-if="row.isDisabled==='0'&& permissionButton.disable"
                           @click="handleDisable(row, index)">禁用</a>
                        <a v-if="row.isDisabled==='1'&& permissionButton.enable"
                           @click="handleEnable(row, index)">启用</a>
                        <Divider type="vertical"/>
                        <a v-if="permissionButton.edit" @click="editUser(row,index)">编辑</a>
                        <template>
                            <Divider type="vertical"/>
                            <template v-if="permissionButton.del">
                                <a @click="deleteUser(row, index)" ghost>删除</a>
                            </template>
                        </template>
                    </template>
                </Table>
                <editUser v-model="editUserShow" :id="id" v-on:getUserList="getUserList"></editUser>
                <addUser v-model="addUserShow" v-on:getUserList="getUserList"></addUser>
                <viewUser v-model="viewUserShow" :id="id"></viewUser>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">
                        共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页
                    </Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize" :total="total" :current="pageNum" @on-change="changePage"
                              @on-page-size-change="changPageSize" :page-size-opts="[15,20,50,100]" show-sizer
                              show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
</template>

<script>
import {reqGetUserListPage, reqGetRoleList, reqDeleteUser, reqEditUserStatus} from "../../../api/system-api";
import editUser from '../user/edit';
import permission from '../../../common/permission';
import addUser from '../user/add';
import viewUser from '../user/view'
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {reqGetDepartmentTreeOpts} from "@/api/admin-api";

export default {
    name: 'UserList',
    components: {
        editUser,
        addUser,
        viewUser,
        TreeSelect
    },
    data() {
        return {
            filter: {
                name: '',
                roleId: '',
                phone: '',
            },
            columns: [
                {
                    title: '序号',
                    type: 'index',
                    key: 'index',
                    width: 80
                },
                {
                    title: '姓名',
                    key: 'name',
                    width: 220
                },
                {
                    title: '性别',
                    key: 'gender',
                    width: 80
                },
                {
                    title: '联系方式',
                    key: 'contactInfo',
                    width: 150
                },
                {
                    title: '员工编号',
                    key: 'empNo',
                    width: 100
                },
                {
                    title: '角色',
                    key: 'roleNames',
                    minWidth: 150
                },
                {
                    title: '所属组织',
                    key: 'departmentNames',
                    minWidth: 150
                },
                {
                    title: '状态',
                    key: 'isDisabled',
                    slot: 'isDisabled',
                    width: 100
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 200
                }
            ],
            id: '',
            total: 0,
            pageNum: 1,
            pageSize: 20,
            userList: [],
            roleArray: [],
            departmentOpts: [],
            pages: 1,
            permissionButton: {
                add: false,
                edit: false,
                del: false,
                view: false,
                disable: false,
                enable: false
            },
            addUserShow: false,
            editUserShow: false,
            viewUserShow: false,
            loading: false,
        }
    },
    methods: {
        //获取全部角色
        getAllRole() {
            this.roleArray = [];
            reqGetRoleList().then((res) => {
                if (res.data) {
                    this.roleArray = this.roleArray.concat(res.data);
                }
            })
        },
        //显示查看页面
        showViewUser(row, index) {
            this.id = row.id;
            this.viewUserShow = true;
        },
        //编辑用户
        editUser(row, index) {
            this.id = row.id;
            this.editUserShow = true;
        },
        //删除角色
        deleteUser(row, index) {
            this.$Modal.confirm({
                title: ' ',
                content: '<p>确定删除该用户？</p>',
                width: '240',
                onOk: () => {
                    this.loading = true;
                    let para = {id: row.id};
                    reqDeleteUser(para).then(res => {
                        this.loading = false;
                        if (res.data.code === 1) {
                            this.$Message.success(res.data.msg);
                            // 删除数据成功同时刷新grid
                            this.getUserList();
                        } else {
                            this.$Message.error(res.data.msg);
                        }
                    }).catch(() => {
                        this.loading = false;
                        this.$Message.error("编辑用户失败");
                    });
                },
            });
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getUserList();
        },
        //每页显示的数据条数
        changPageSize(index) {
            //实时获取当前需要显示的条数
            this.pageSize = index;
            this.getUserList();
        },
        //获取分页列表
        getUserList() {
            let that = this;
            that.loading = true;
            let para = {
                pageNum: that.pageNum,
                pageSize: this.pageSize,
                name: this.filter.name,
                roleId: this.filter.roleId,
                phone: this.filter.phone,
                departmentId: this.filter.departmentId
            };
            reqGetUserListPage(para).then((res) => {
                this.loading = false
                that.total = res.data.data.total;
                that.userList = res.data.data.records;
                that.pages = res.data.data.pages;
            })
        },
        //显示添加页面
        showAddDialog: function () {
            this.addUserShow = true;
        },
        //禁用用户
        handleDisable(row, index) {
            this.$Modal.confirm({
                title: ' ',
                content: '确定禁用该用户？',
                width: '240',
                onOk: () => {
                    let para = Object.assign({}, row);
                    para.isDisabled = '1';
                    delete para['createTime'];
                    delete para['updatedTime'];
                    reqEditUserStatus(para).then((res) => {
                        this.$Message.success("禁用用户成功");
                        this.getUserList();
                    }).catch((e) => {
                        this.$Message.error("禁用用户失败");
                    });
                }
            });
        },
        //启用用户
        handleEnable(row, index) {
            this.$Modal.confirm({
                title: ' ',
                content: '确定启用该用户？',
                width: '240',
                onOk: () => {
                    let para = Object.assign({}, row);
                    para.isDisabled = '0';
                    delete para['createTime'];
                    delete para['updatedTime'];
                    reqEditUserStatus(para).then((res) => {
                        this.$Message.success("启用用户成功");
                        this.getUserList();
                    }).catch(() => {
                        this.$Message.error("启用用户失败");
                    });
                }
            });
        },
        getDepartmentTreeOpts() {
            reqGetDepartmentTreeOpts().then(res => {
                this.departmentOpts = res.data.data;
            }).catch(()=>{
                this.departmentOpts = [];
            });
        },
    },
    mounted() {
        permission.getPermissionButton(this.$route.query.permissionId, this.permissionButton);
        this.getAllRole();
        this.getUserList();
        this.getDepartmentTreeOpts();
    },
}
</script>
