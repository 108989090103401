<template>
    <Modal v-model="show" title="编辑用户" :width="640" :mask-closable="false">
        <Form ref="editForm" :model="editForm" :rules="buttonFormRule" label-colon :label-width="90">
            <FormItem label="角色" prop="roleIds">
                <Select v-model="editForm.roleIds" multiple placeholder="请选择">
                    <Option v-for="item in roleArray" :label="item.roleName" :value="item.roleId"
                            :key="item.roleId"></Option>
                </Select>
            </FormItem>
            <FormItem label="姓名" prop="name">
                <Input v-model="editForm.name" show-word-limit :maxlength="20" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="性别" prop="gender">
                <RadioGroup v-model="editForm.gender">
                    <Radio label="男">男</Radio>
                    <Radio label="女">女</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="联系方式" prop="contactInfo">
                <Input v-model="editForm.contactInfo" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="员工编号" prop="empNo">
                <Input type="text" v-model.trim="editForm.empNo" show-word-limit :maxlength="20" placeholder="请输入"/>
            </FormItem>
            <FormItem label="所属组织" prop="departmentIds">
                <TreeSelect v-model="editForm.departmentIds" :options="departmentOpts" :multiple="true" :flat="true" placeholder="请选择" noOptionsText="暂无数据" noResultsText="没有匹配数据" :clear-on-select="clearOnSelect" clearable />
            </FormItem>
            <FormItem label="用户名" prop="userName">
                <Input :disabled=true v-model.trim="editForm.userName" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="密码" prop="password">
                <Input type="password" v-model="editForm.password" placeholder="若不输入，则原密码不变" clearable/>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="submit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqGetRoleList, reqEditUserData, reqGetUserById} from "../../../api/system-api";
import {checkPhone} from "@/common/publicFuns";
import {reqGetDepartmentTreeOpts} from "../../../api/admin-api";
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
    name: "editUser",
    components: {TreeSelect},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: ''
    },
    data() {
        return {
            show: false,
            loading: false,
            clearOnSelect: true,
            roleArray: [],
            departmentOpts: [],
            editForm: {
                roleIds: null,
                name: '',
                gender: '男',
                contactInfo: '',
                empNo: '',
                departmentIds: null,
                userName: '',
                password: '',
            },
            buttonFormRule: {
                name: [
                    {required: true, message: '请输入姓名', trigger: 'blur'},
                ],
                userName: [
                    {required: true, message: '请输入用户名', trigger: 'blur'},
                ],
                password: [
                    {required: false, message: '请输入密码', trigger: 'blur'},
                    {
                        pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![!#$%^&*]+$)[\da-zA-Z!#$%^&*]{6,16}$/,
                        message: '密码由字母和数字组成6到16位',
                        trigger: 'blur'
                    },
                ],
                contactInfo: [{
                    trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (value && !checkPhone(value)) {
                            return callback(new Error('联系电话不正确(座机格式\'区号-座机号码\')'));
                        }
                        return callback();
                    }
                }],
            },
        }
    },
    methods: {
        // base64加密开始
        encode64(input) {
            let keyStr = "ABCDEFGHIJKLMNOP" + "QRSTUVWXYZabcdef" + "ghijklmnopqrstuv"
                + "wxyz0123456789+/" + "=";
            let output = "";
            let chr1, chr2, chr3 = "";
            let enc1, enc2, enc3, enc4 = "";
            let i = 0;
            do {
                chr1 = input.charCodeAt(i++);
                chr2 = input.charCodeAt(i++);
                chr3 = input.charCodeAt(i++);
                enc1 = chr1 >> 2;
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                enc4 = chr3 & 63;
                if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
                } else if (isNaN(chr3)) {
                    enc4 = 64;
                }
                output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2)
                    + keyStr.charAt(enc3) + keyStr.charAt(enc4);
                chr1 = chr2 = chr3 = "";
                enc1 = enc2 = enc3 = enc4 = "";
            } while (i < input.length);

            return output;
        },
        submit() {
            this.$refs['editForm'].validate((valid) => {
                if (!valid) {
                    return;
                }
                this.loading = true;
                if (this.editForm.password) {
                    this.editForm.password = this.encode64(this.encode64(this.editForm.password) + 'pd');
                }
                delete this.editForm['createTime'];
                delete this.editForm['updatedTime'];
                let para = Object.assign({}, this.editForm)
                reqEditUserData(para).then(res => {
                    this.loading = false;
                    if (res.data.code == 1) {
                        this.$Message.success(res.data.msg);
                        // 提交表单数据成功则关闭当前的modal框
                        this.closeModal(false);
                        // 同时调用父页面的刷新页面的方法
                        this.$emit('getUserList');
                    } else {
                        this.$Message.error(res.data.msg);
                    }
                }).catch(() => {
                    this.loading = false;
                    this.$Message.error("编辑用户失败");
                });
            });
        },
        closeModal(val) {
            this.$emit('input', val);
        },
        init() {
            let promiseRole = new Promise((resolve, reject) => {
                reqGetRoleList().then((res) => {
                    this.roleArray = res.data;
                    resolve(true);
                }).catch(()=>{
                    this.roleArray = [];
                });
            });
            let promiseDept = new Promise((resolve, reject) => {
                reqGetDepartmentTreeOpts().then(res => {
                    this.departmentOpts = res.data.data;
                    resolve(true);
                }).catch(()=>{
                    this.departmentOpts = [];
                });
            });

            Promise.all([promiseRole, promiseDept]).then(value => {
                //通过Id查询用户信息
                reqGetUserById({id: this.id}).then(res => {
                    let user = res.data.data;
                    if (user.roleIds) {
                        user.roleIds = user.roleIds?.split(',').map(item => item * 1);
                    }
                    if (user.departmentIds){
                        user.departmentIds = user.departmentIds?.split(',');
                    }

                    this.editForm = user;
                });
            }).catch(reason => {
                console.log(reason);
            })
        }
    },
    mounted() {},
    watch: {
        value(val) {
            this.show = val;
        },
        show(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['editForm'].resetFields();
                this.init();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
